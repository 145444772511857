import React from 'react'
import { graphql } from 'gatsby'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import FadeInSection from '../components/fadeinsection'
import ResponsiveHeader from '../components/responsiveheader'
import Button from '../components/button'
import EmailForm from "../components/emailform";

import { BG_IMG_CLASSES } from "../helpers/utils"

const BlogPostTemplate = ({ data }) => {
    const { site } = data
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    const feature = getImage(frontmatter.image)
    const authorAvatar = getImage(frontmatter.avatar)
    const formattedTweet = encodeURIComponent(`${frontmatter.title}: "${frontmatter.summary}" @TopDogStudios`)
    const postUrl = `${site.siteMetadata.siteUrl}${markdownRemark.fields.slug}`
    
    const isDark = frontmatter.theme == null || frontmatter.theme == "dark"
    const bgTheme = isDark ? "black" : "white"
    const fgTheme = isDark ? "white" : "black"
    const fromTheme = isDark ? "from-black" : "from-white"
    
    return (
      <Layout bodyClasses={`bg-${bgTheme}`}>
        <SEO title={frontmatter.title} url={postUrl} description={frontmatter.summary} image={`${site.siteMetadata.siteUrl}/seo/${markdownRemark.id}.jpg`} />

        <FadeInSection>
          <div className="w-full grid mx-auto mb-6 max-w-screen-lg">
            <GatsbyImage
              image={feature}
              alt={frontmatter.summary}
              loading="eager"
              className={BG_IMG_CLASSES}
              style={{ gridArea: '1/1' }}
            />
            <div
              className={`absolute inset-0 bg-gradient-to-t ${fromTheme} to-transparent ${BG_IMG_CLASSES}`}
              style={{ gridArea: '1/1' }}
            ></div>

            <div className="grid relative" style={{ gridArea: '1/1' }}>
              <div className="flex flex-col z-10">
                <ResponsiveHeader
                  size={4}
                  className={`text-${fgTheme} flex-grow p-6 font-bold uppercase`}
                >
                  <span style={{ wordSpacing: '100vw' }}>
                    {frontmatter.title}
                  </span>
                </ResponsiveHeader>

                <div className={`flex justify-start items-center p-6 w-full bg-${fgTheme} rounded-lg`}>
                  <GatsbyImage
                    image={authorAvatar}
                    alt={frontmatter.author}
                    className="rounded-full shadow-sm mr-6"
                  />

                  <div className={`text-${bgTheme} font-bold text-sm md:text-base`}>
                    <p>Published {frontmatter.date}</p>
                    <p>Written by {frontmatter.author}</p>
                    {frontmatter.bio && <p className="text-sm text-gray-500">
                      {frontmatter.bio}
                    </p>}
                  </div>

                  <div className="flex flex-grow justify-end">
                    <Button
                      action={`https://twitter.com/intent/tweet?text=${formattedTweet}&url=${postUrl}`}
                    
                    >
                      <FaTwitter className="inline-block md:mr-2" />
                      <span className="hidden md:inline">Share</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>

        <div
          className={`mx-auto mt-12 prose ${isDark && "dark:prose-invert text-white"} lg:prose-lg xl:prose-lg text-justify border-b-2 pb-12`}
        >
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
          <p>The Top Dog Team ❤</p>
        </div>

        {/* <div className="mx-auto w-full md:w-3/4 lg:w-2/4 mt-6 p-6 bg-gray-900 rounded-md">
          <EmailForm
            formName="register"
            placeholder="Enter your e-mail address for updates"
            formHint="One e-mail a week. Unsubscribe at any time."
          />
        </div> */}
      </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        author
        bio
        date(formatString: "MMMM DD, YYYY")
        year: date(formatString: "YYYY")
        summary
        theme
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        avatar {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 64, height: 64)
          }
        }
      }
      fields {
        slug
      }
    }
  }
`